@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&display=swap");

/* Generals */
html,
body,
#root .app {
  height: 100vh;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.7" d="M0,96L24,85.3C48,75,96,53,144,90.7C192,128,240,224,288,245.3C336,267,384,213,432,165.3C480,117,528,75,576,48C624,21,672,11,720,21.3C768,32,816,64,864,64C912,64,960,32,1008,48C1056,64,1104,128,1152,160C1200,192,1248,192,1296,213.3C1344,235,1392,277,1416,298.7L1440,320L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>');
  background-repeat: repeat-x;
  background-size: 150vw; /* maintain aspect ratio */
  animation: slideBg2 120s infinite linear;
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  .app {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.7" d="M0,96L24,85.3C48,75,96,53,144,90.7C192,128,240,224,288,245.3C336,267,384,213,432,165.3C480,117,528,75,576,48C624,21,672,11,720,21.3C768,32,816,64,864,64C912,64,960,32,1008,48C1056,64,1104,128,1152,160C1200,192,1248,192,1296,213.3C1344,235,1392,277,1416,298.7L1440,320L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>');
    background-repeat: repeat-x;
    background-size: 150vw; /* maintain aspect ratio */
    animation: slideBg2 120s infinite linear;
    overflow: auto;
    overflow-x: hidden;
  }
}

.app::before,
.app::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150vw;
  animation: slideBg 120s infinite linear;
}

.app::before {
  top: -20vh;
  /*   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.5" d="M0,224L40,213.3C80,203,160,181,240,197.3C320,213,400,267,480,261.3C560,256,640,192,720,160C800,128,880,128,960,138.7C1040,149,1120,171,1200,165.3C1280,160,1360,128,1400,112L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>');
 */
  transform: translateZ(0);
}

.app::after {
  bottom: -10vh;
  /*   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.5" d="M0,0L40,42.7C80,85,160,171,240,181.3C320,192,400,128,480,128C560,128,640, 192,720,186.7C800,181,880,107,960,69.3C1040,32,1120,32,1200,32C1280,32,1360,32,1400,32L1440,32L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>');
 */
  transform: scaley(-1);
}

@keyframes slideBg {
  0% {
    background-position: 0 -1vh;
  }
  25% {
    background-position: 10% -4vh;
    background-repeat: repeat-x;
  }
  50% {
    background-position: 25% -4vh;
    background-repeat: repeat-x;
  }
  75% {
    background-position: 14% -4vh;
    background-repeat: repeat-x;
  }
  100% {
    background-position: 0 -1vh;
  }
}

@keyframes slideBg2 {
  0% {
    background-position: 0 -1vh;
  }
  25% {
    background-position: 7% -3vh;
    background-repeat: repeat-x;
  }
  50% {
    background-position: 30% -6vh;
    background-repeat: repeat-x;
  }
  75% {
    background-position: 7% -2vh;
    background-repeat: repeat-x;
  }
  100% {
    background-position: 0 -1vh;
  }
}
@-webkit-keyframes slideBg {
  0% {
    background-position: 0 -1vh;
  }
  25% {
    background-position: 10% -4vh;
    background-repeat: repeat-x;
  }
  50% {
    background-position: 25% -4vh;
    background-repeat: repeat-x;
  }
  75% {
    background-position: 14% -4vh;
    background-repeat: repeat-x;
  }
  100% {
    background-position: 0 -1vh;
  }
}

@-webkit-keyframes slideBg2 {
  0% {
    background-position: 0 -1vh;
  }
  25% {
    background-position: 10% -4vh;
    background-repeat: repeat-x;
  }
  50% {
    background-position: 25% -4vh;
    background-repeat: repeat-x;
  }
  75% {
    background-position: 14% -4vh;
    background-repeat: repeat-x;
  }
  100% {
    background-position: 0 -1vh;
  }
}

/* Animationen für Safari-Browser deaktivieren */
@media screen and (max-width: 1100px) {
  @supports (-webkit-appearance: none) {
    .app {
      animation: none !important;
    }

    .app::before,
    .app::after {
      animation: none !important;
    }
  }
}
/* Login / Register */
/* Register */

.eXTRAgROSS {
  overflow: auto;
}
.TextFieldInputLabelText {
  width: 100%;
}

.TextFieldInputLabel {
  padding: 0;
  margin: 0;
}

.InputLabel {
  width: 100%;
  height: 3vh;
  padding: 0;
  margin-top: 3vh;
  text-align: center;
}

.inputFieldRegisterBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.inputFieldRegisterForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inputFieldRegister {
  width: 300px;
  height: 5vh;
  border-radius: 5px;
  border: 1px solid #fcfcfc;
  background-color: #2a1e36;
  color: #fcfcfc;
  font-size: 18px;
  padding-left: 10px;
  margin-bottom: 2vh;
}
.inputFieldRegister2 {
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  border: 1px solid #fcfcfc;

  color: #fcfcfc;
  font-size: 18px;
  padding-left: 10px;
  margin-bottom: 2vh;
}
.LinkUserSide {
  color: #e0e0e0;
  text-decoration-line: underline;
  text-decoration-color: none;
}

/* Hinzugefügte Klassen */
.GridItemMargin {
  margin-bottom: 3vh;
  width: 100%;
}

.GridContainerMargin {
  margin-bottom: 3vh;
  width: 100%;
  padding: 10px;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.GridContainerMarginGrid {
  width: 100%;
}

.LoginBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2vh;
}

.LoginBtn {
  width: 66%;
}
.LoginBtnSG {
  width: 45%;
}

.BoxPaddingTop {
  padding-top: 2vh;
  justify-content: center;
}

.TypographyColor {
  color: #e0e0e0;

  text-align: center;
}

.paper {
  background: #2a1e36;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ModalRegister {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Hintergrund mit Transparenz */
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: #2a1e36;
}

.modal-containerTwo {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 85%;
  align-items: center;
  border-radius: 2em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto;
  background: #2a1e36;
}
.modal-containerTwoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background: #2a1e36;
  border-radius: 2em 2em 0 0;
  padding: 1em;
}

.LoginBtnThree {
  width: 4px;
  height: 4px;
  background: #2a1e36;
  border: 1px solid #fcfcfc;
  border-radius: 5px;
  color: #fcfcfc;
  font-size: 1.5em;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 2vh;
}

.LoginBtnFour {
  width: 66%;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.modal-content {
  background: #2a1e36;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ModalRegisterContent {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: #2a1e36;
}
.progress-line-container {
  width: 100%;
  height: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}
.progress-line {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
}
.ModalRegImg {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .ModalMobil {
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .ModalRegister {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Optional: Hintergrund mit Transparenz */
  }
  .ModalRegImg {
    width: 100%;
  }
  .ModalRegisterContent {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: #2a1e36;
  }
}

.addForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-top: 2vh;
  overflow: auto;
}

.addForm > div {
  width: 100%;
}

/* Ergebnisse Submit */
.rightContainerS {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
  width: 100vw;
}

.ErgebnisseContainerS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-bottom: 10vh;
}
.TextFieldInputUserS {
  border: 1px solid #e0e0e0;
  width: 60vw;
}

.selectInputUserErgS {
  border: 1px solid #e0e0e0;
  width: 60vw;
}
.TextFieldInputUserT {
  width: 60vw;
}

.TextFieldInputUserS .MuiAutocomplete-endAdornment {
  border: none;
}
.css-oic25h-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input
  .MuiOutlinedInput-input {
  padding: none;
  border: none;
}

.MuiOutlinedInput-notchedOutline {
  margin: 0;
  border: none;
}

.TopbarLinkS {
  width: 100%;
  color: #e0e0e0;
  text-decoration-line: none;
  text-decoration-color: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.TextFieldInputUserT {
  font-size: larger;

  margin-bottom: 1em;
}

/*Downloads*/
.rightContainerDond {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: max-content;
  padding-bottom: 5vh;
  margin-bottom: 10px;
}
.DownloadWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10%;
  height: max-content;
  padding-bottom: 5vh;
}

.Downloads {
  width: 45%;
  padding: 20px;
  border-radius: 5px;

  cursor: pointer;
  background: #2a1e36;
}

h3 {
  margin-top: 0;
}

.QRCodeModal {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2a1e36;
  padding: 30px;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.QRCodeModalTwo {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2a1e36;
  margin-top: 2rem;
  border-radius: 5px;
  height: 80vh;
}

.PlatzeinweiserSite {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.QRCodeSite {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}
.QRCodeSiteTwo {
  height: 100%;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  padding-bottom: 60px;
}
.QRCodeSiteThree {
  height: 100%;
  padding-left: 1rem;
  padding-bottom: 80px;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
}

h3 {
  margin-top: 0;
}

.QRCodeWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.QRCodeWrapper p {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

@media screen and (max-width: 1100px) {
  .rightContainerDond {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    height: fit-content;
    margin-bottom: 4rem;
    padding-bottom: 4rem;
  }
  .Downloads {
    width: 90%;

    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    background: #2a1e36;
  }
}

/* Sidbar */
.Sidebar {
  height: 100%;
}

.Sidebar a {
  text-decoration: none;
}
.SideBarList {
  height: 100%;
  width: auto;
}

.SidebarItemText::after {
  content: "";
  display: none;
}

.SidebarItemLogOut {
  position: absolute;
  bottom: 0;
  margin-top: 100;
  width: 100%;
}
/* Für Bildschirme mit einer maximalen Breite von 760px */
@media screen and (max-width: 760px) {
  .SidebarItemLogOut {
    position: relative;
  }
  .LinkSide-Item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Für Bildschirme mit einer maximalen Höhe von 480px */
@media screen and (max-height: 480px) {
  .SidebarItemLogOut {
    position: relative;
  }
  .LinkSide-Item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .LinkSide2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
  }
}

.LinkSide2 {
  color: rgb(254, 248, 239);
  text-decoration-line: none;
  text-decoration-color: none;
}
.content {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.rightContent {
  width: 100%;
  transition: 0.5s ease;
}

::-webkit-scrollbar {
  width: 10px;
}
/* Track */

::-webkit-scrollbar-track {
  background: #120520;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #2a1e36;
}

/* Handle on Hover */

::-webkit-scrollbar-track:hover {
  background: #2a1e36;
}

.ListTurnierInfoTitelLive {
  justify-content: center;
}
.glowDot {
  justify-content: center;
  margin-top: 2vh;
}

/* DashBoard */
@media screen and (max-width: 1100px) {
  .listItemOrt,
  .listItemAdmin {
    display: none;
  }

  .ListTurnierInfoTitel,
  .ListTurnierInfoContent,
  .ListTurnierInfoContentTwo {
    justify-content: space-between;
    overflow-x: hidden;
  }
  .ListTurnierInfoTitelLive {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .glowDot {
    justify-content: center;
    align-items: flex-start;
  }
  .listItemSpielen {
    justify-content: center;
  }
}

/* * Downloads * */
/* QR-Code */

.rightContainerD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-bottom: 10%;
}

.DownloadWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  height: 100%;
}

.Download {
  width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

.Download:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
}

#ListItemDashboard {
  display: flex;
  justify-items: space-between;
}
#ListItemDashboard:hover {
  background: #2a1e36;
}

.LinkSide {
  color: rgb(254, 248, 239);
  text-decoration: none;
}

#ListItemPlayerGlobal {
  display: flex;
  justify-items: space-between;
  flex-direction: row;
  height: 100%;
}
#ListItemTurnierGlobalItem {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1100px) {
  #ListItemPlayerGlobal {
    display: flex;
    justify-content: flex-start;
    width: auto;
    padding-bottom: 1rem;
  }
  #ListItemTurnierGlobalItem {
    display: flex;
    justify-content: flex-start;
    width: auto;
  }
  .ListTurnierInfoContentALL {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 60px;
  }
  .ListTurnierInfoContent {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ListTurnierInfoTitel {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ListTurnierInfo {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ListTurnierInfoTitel {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ModalAllTurnier {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: center;
}

.ModalAllTurnierTI {
  width: 50%;
  text-align: start;
}
.ModalAllTurnierSI {
  width: 50%;
  text-align: start;
  margin-left: 1rem;
  padding-left: 2rem;
}

@media screen and (max-width: 760px) {
  .ModalAllTurnier {
    flex-direction: column;
    width: 100%;
  }
  .ModalAllTurnierTI {
    width: 100%;
  }
  .ModalAllTurnierSI {
    width: 100%;
    margin-top: 1rem;
    padding: 0;
    margin-left: 0;
    text-align: center;
  }
}

#ListItemTurnierGlobal:hover {
  background: #525252;
}

#ListItemPlayerGlobal:hover {
  background-color: #e0e0e0;
  color: #525252;
}

.TurnierTabelle,
table {
  font-family: "Montserrat", sans-serif;
  border: 0px solid #000102;
  background-color: #8b637f;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.TurnierTabelle td,
.TurnierTabelle th {
  padding: 3px 2px;
}
.TurnierTabelle tbody td {
  font-size: 13px;
  color: #ffffff;
}
.TurnierTabelle tr:nth-child(even) {
  background: #8b637f;
}
.TurnierTabelle thead {
  background: #802d84;
  border-bottom: 2px solid #ececec;
}
.TurnierTabelle thead th {
  font-size: 15px;
  font-weight: normal;
  color: #ffffff;
}
.TurnierTabelle thead th:first-child {
  border-left: none;
}

.TurnierTabelle tfoot {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background: #802d84;
  border-top: 2px solid #444444;
}
.TurnierTabelle tfoot td {
  font-size: 12px;
}
.TurnierTabelle tfoot .links {
  text-align: right;
}
.TurnierTabelle tfoot .links button {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  padding: 2px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.SubHeader {
  background: "#525252";
  padding: 2em;
}

.LiveBodyContainer {
  background: "#525252";
  padding: 2em;
}

.RundenCounterBar {
  display: flex;
  align-items: center;
}

.TischCounterBar {
  display: flex;
  align-items: center;
}

.LivePreviewBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Media Query for 760px screen size */
@media (max-width: 760px) {
  .SubHeaderTurnier {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .txtBox,
  .arrowBox {
    flex-basis: 45% !important;
    max-width: 45% !important;
    padding: 3px !important;
    margin-left: 5px !important;
    margin-top: 5px !important;
  }
  .btnBox {
    flex-basis: 100% !important;
    max-width: 100% !important;
    padding: 3px !important;
    margin-left: 1px !important;
    margin-top: 5px !important;
  }
}

.SignInComponent {
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.SignInComponent form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SignInComponent label {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.SignInComponent input {
  margin-top: 8px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.SignInComponent div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.SignInComponent button {
  margin-top: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #2a1e36;
  width: 100%;
  color: #e0e0e0;
}

.SignInComponent p {
  font-size: 12px;
}

#GastBtn {
  width: 100%;
}

.SignInComponent .LinkTag {
  color: #e0e0e0;
}

#GastButton {
  margin-top: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #2a1e36;
  width: 100%;
  text-align: center;
  color: #e0e0e0;
  text-decoration-line: none;
}

.LinkTagAdd {
  margin-top: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #2a1e36;
  width: 100%;
  text-align: center;
  color: #e0e0e0;
  text-decoration-line: none;
}
.TopbarLink {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #e0e0e0;
  font-family: "Montserrat", sans-serif;
  font-size: 40;
}
.HeaderLink {
  text-decoration: none;
  color: #e0e0e0;
  font-family: "Montserrat", sans-serif;
  font-size: 40;
}

.ProfileHub h2 {
  font-size: 24px;
}
.ProfileHub p {
  font-size: 18px;
}
.ProfileHub button {
  padding: 10px;
  background-color: lightgray;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.PrivateSwitchBase-input {
  background-color: #fcfcfc;
  color: #fcfcfc;
}

.ListTurnierInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  overflow: auto;
}

.ListTurnierInfoTitel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background-color 0.3s ease-in-out;
}

.ListTurnierInfoContentALL {
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 65vh;
}

.ListTurnierInfoContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: 18px;
}
.ListTurnierInfoContentTwo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: 18px;
}
.ListTurnierInfoContentTwo:hover {
  background-color: #e0e0e0;
  color: #000000;
}

@media screen and (max-width: 760px) {
  .ListTurnierInfoTitel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ListTurnierInfoContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 15px;
    margin-top: 2vh;
    border-top: 3px solid #2a1e36;
    border-bottom: 3px solid #2a1e36;
    /*     box-shadow: 3px 5px 5px 1px rgba(0, 0, 0, 0.5);
 */
  }
  .ListTurnierInfoContent,
  .ListTurnierInfoContentTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 15px;
    margin-top: 2vh;
    border-top: 3px solid #2a1e36;
    border-bottom: 3px solid #2a1e36;
  }
  .ListTurnierInfoContent:first-of-type,
  .ListTurnierInfoContentTwo:first-of-type {
    border-top: 6px solid #2a1e36;
  }
  .ListTurnierInfoContent:last-of-type,
  .ListTurnierInfoContentTwo:last-of-type {
    border-bottom: 6px solid #2a1e36;
  }

  .glowDot {
    align-self: center;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

/*  Ergebnisse  */
.ErgebnisseWeb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  margin-top: 15vh;
  margin-left: 5vw;
}

.ErgebnisseTab {
  width: 100%;
  border-right: 0;
}

@media screen and (max-width: 760px) {
  .ErgebnisseWeb {
    width: 85vw;
  }

  .ErgebnisseGridTwo {
    display: grid;
    grid-template-columns: 1fr;
    height: 75vh;
    width: 100%;
    border: none;
  }

  .ErgebnisseTab {
    width: 100%;
    border-right: 0;
  }
  .ErgebnisseTabTwo {
    width: 75vw;
    border-right: 0;
  }
}

@media screen and (max-width: 1100px) {
  .Sidebar {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 60px; /* Adjust the height as per your requirement */
    display: none;
    align-items: center;
  }
  .HeaderLink {
    display: none;
    padding: 0;
    margin: 0;
  }

  .mobilSidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: none;
    margin: none;
  }

  .SideBarList {
    display: flex;
    align-items: center; /* Center the items vertically */
    justify-content: space-between; /* Center the items horizontally */
    width: 100%;
    height: 60px;
    overflow-x: scroll;
    height: auto;
    scrollbar-width: none; /* Firefox */
    padding: 0;
    margin: 0;
  }
  .SideBarList::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .SidebarButton {
    display: none;
  }
  .SidebarItemLogOut {
    position: relative;
    width: min-content;
  }
  .LinkSide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1vh;
  padding: 0.5vh;
  color: #fcfcfc;
}

input[type="text"] {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  height: 5vh;
  background-color: #2a1e36;
  color: #e0e0e0;
}

input[type="text"]::placeholder {
  color: #e0e0e0;
  opacity: 1;
}

.TextFieldInputUser {
  outline: #e0e0e0;
  width: 90%;
}

.SelectInputUser {
  background-color: #2a1e36;
  border: 1px solid #e0e0e0;
}

.SelectInputUserErg {
  background-color: #2a1e36;
  border: 1px solid #e0e0e0;
  width: 90%;
}

.liveTurnierContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  width: 100%;
  height: 85vh;
}

.fullscreenFallback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.CloseIcon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}
.closeFullscreen {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 8px;
  right: 8px;
  z-index: 9999;
}

.fullscreenBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  width: 100%;
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1542731247-8b8b5b5e5c5d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");
}

.fullscreenBackground::before,
.fullscreenBackground::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: slideBg 120s infinite linear;
}

.fullscreenBackground::before {
  top: -20vh;
  /*   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.6" d="M0,224L40,213.3C80,203,160,181,240,197.3C320,213,400,267,480,261.3C560,256,640,192,720,160C800,128,880,128,960,138.7C1040,149,1120,171,1200,165.3C1280,160,1360,128,1400,112L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>');
 */
}

.fullscreenBackground::after {
  bottom: -10vh;
  /*   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.6" d="M0,0L40,42.7C80,85,160,171,240,181.3C320,192,400,128,480,128C560,128,640,192,720,186.7C800,181,880,107,960,69.3C1040,32,1120,32,1200,32C1280,32,1360,32,1400,32L1440,32L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>');
 */
  transform: scaley(-1);
}

.fullscreenContainer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.fullscreenContainer.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background-color: #2a1e36;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox=" 0 0 1740 640 "><path fill="%23Eca524" fill-opacity="0.5" d="M0,0L40,42.7C80,85,160,171,240,181.3C320,192,400,128,480,128C560,128,640, 192,720,186.7C800,181,880,107,960,69.3C1040,32,1120,32,1200,32C1280,32,1360,32,1400,32L1440,32L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150vw;
  animation: slideBg 120s infinite linear;
}

::-webkit-full-screen .liveTurnierContainer {
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
}

::-webkit-full-screen .liveTurnierContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chartWrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::-webkit-full-screen .chartWrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Landscape mode for mobile devices */

@media screen and (max-width: 760px) {
  .liveTurnierContainer.single-chart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .liveTurnierChartWrapper {
    min-width: calc(50% - 0.5rem);
    max-height: calc(50% - 0.5rem);
  }
}
.ProfilHubHeder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

/* Profil-Karten-Stile */
.avatar {
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: #fcfcfc;
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  overflow: hidden;
  margin-top: 5vh;
}

@media screen and (max-width: 760px) {
  .avatar {
    width: 85px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    overflow: hidden;
  }
  .ProfilHub {
    overflow: hidden;
  }
}

/* Typografie-Stile */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
}

.subtitle1 {
  color: #666;
}

.body2 {
  color: red;
  margin-bottom: 20px;
}

.subtitle2 {
  color: #333;
  font-weight: bold;
}

/* Material-UI-Farben anpassen */
.MuiIconButton-colorPrimary {
  color: #333;
}

.MuiDivider-root {
  background-color: #fcfcfc;
}

.MuiPaper-elevation1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.MuiPaper-rounded {
  border-radius: 2px;
}

.MuiGrid-root {
  margin: 0;
  width: 100%;
}

/* Primärfarbe anpassen */
.MuiBox-root.MuiBox-root-1 {
  background-color: #333;
}

.NoListItemDash {
  list-style-type: none;
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.NoListItemTur {
  list-style-type: none;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background-color: #fcfcfc;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
}

.modalTitle {
  margin-top: 0;
  color: #141b2d;
}

.modalText {
  margin-bottom: 20px;
  color: #000102;
}

.modalImage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
}

.modalButton {
  margin-top: 16px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #120520;
  width: 100%;
  color: #e0e0e0;
}

.spanTitel {
  font-size: 20px;
  color: #141414;
  font-weight: bold;
  margin-bottom: 10px;
}

.spanTitelT {
  font-size: 16px;
  color: #141414;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 2em;
  margin-right: 1vw;
}

.DialogProfile {
  background-color: #120520;
}

.FormControlCheckbox {
  padding-bottom: 120px;
}
.DialogAction {
  background-color: #120520;
}

.DialogProfileContainer {
  padding-bottom: 100px;
}
.chartContainerFull {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.chartWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(20%), calc(38%)));
  grid-auto-rows: minmax(calc(20%), calc(38%));
  grid-gap: 1rem;
  width: "90VW";
  height: 90vh;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: relative;

}

.chartContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(242, 242, 242, 0.1); /* Replace with your desired background color and opacity */
  margin: 0.5rem;
  }

.chartCanvas {
  width: 100%;
  height: 100%;

}

/* Neuanfang CSS:  */
.RegContainer {
  margin-top: 50px;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.SubHeaderTurnier {
  width: 100%;
  padding-bottom: 2vh;
  padding-left: 2vw;
}

.txtBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.arrowBox {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.btnBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.arrowBoxContent {
  display: flex;
  justify-content: left;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .arrowBoxContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
}

.modal-box {
  color: #141b2d;
  background-color: #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  max-width: 600px;
}

.modal-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.profile-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.modal-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-left: 24px;
}

.modal-name {
  margin: 8px;
  color: #141b2d;
  width: 100%;
}

.modal-contact {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
}

.modal-bio {
  margin-top: 50px;
  color: #141b2d;
}

.modal-close {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 760px) {
  .modal-box {
    width: 90vw;
    padding: 24px;
  }

  .modal-profile {
    flex-direction: column;
    align-items: center;
  }

  .modal-details {
    margin-top: 16px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }

  .modal-contact {
    margin-top: 1vh;
    width: 100%;
    justify-content: space-evenly;
  }

  .modal-bio {
    text-align: center;
  }

  .modal-close {
    justify-content: center;
  }
}

@media screen and (max-width: 1100px) {
  .rightContent {
    height: max-content;
    position: relative;
    padding-bottom: 80px;
    overflow-x: hidden;
  }
  .ListTurnierInfo {
    overflow: hidden;
  }
  .app {
    overflow-y: scroll;
  }
  .inputFieldRegisterForm {
    width: 100%;
    height: 15vh;
  }
  .RegContainer {
    margin-top: 0px;
    padding-bottom: 60px;
  }
  .inputFieldRegister {
    height: 5vh;
    border-radius: 5px;
    border: 1px solid #fcfcfc;
    background-color: #2a1e36;
    color: #fcfcfc;
    font-size: 18px;
    padding-left: 10px;
    margin-bottom: 1vh;
  }

  .inputFieldRegister2 {
    width: 100%;
    height: 5vh;
    border-radius: 5px;
    background-color: none;
    padding-left: 10px;
    margin-bottom: 5vh;
  }
  .rightContainerS {
    padding-bottom: 80px;
  }
}

.singleModeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  width: 85vw;
  padding: 10px;
  gap: 16px;
}

.SingleModeInput {
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  border: 1px solid #fcfcfc;
  background-color: #2a1e36;
  color: #fcfcfc;
  font-size: 18px;
  padding-left: 10px;
  margin-bottom: 1vh;
}

@media (max-width: 1100px) {
  .singleModeContainer {
    width: 100vw;
    height: 100vh;
    padding: 8px;
    gap: 8px;
  }
}

.playerRatingForm {
  height: auto;
  padding-bottom: 60px;

  padding-left: 3rem;
  padding-right: 3rem;
}

.playerGridContainer {
  padding-top: 2rem;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  margin-top: 2rem;
}
.backButton {
  width: 50%;
}
.playerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.playerName {
  text-align: end;
  margin-bottom: 1vh;
  padding-bottom: 1vh;
}

.playerValueInput {
  width: 80px;
}

.playerValueInput:first-of-type {
  align-items: flex-end;
  display: flex;
}

@media (max-width: 1100px) {
  .endBox {
    padding-bottom: 60px;
  }
}

.buttonBoxContainer {
  width: 100vw;
  margin: 3rem auto;
}

.würfelBox,
.trophaeBox,
.profilBox,
.peopleBox,
.liveBox,
.trophäeIconBox,
.downloadsBox,
.settingsBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #eca524;
  display: none;
}

@media (max-width: 1100px) {
  .singleModeButton,
  .turnierErstellenButton,
  .profilIconButton,
  .peopleIconButton,
  .liveIconButton,
  .trophäeIconButton,
  .mehrButton,
  .einstellungenButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    color: #802d84;
  }
  .singleModeButton:hover,
  .turnierErstellenButton:hover,
  .profilIconButton:hover,
  .peopleIconButton:hover,
  .liveIconButton:hover,
  .trophäeIconButton:hover,
  .mehrButton:hover,
  .einstellungenButton:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .würfelBox,
  .trophaeBox,
  .profilBox,
  .peopleBox,
  .liveBox,
  .trophäeIconBox,
  .downloadsBox,
  .settingsBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #2a1e36;
    opacity: 0.8;
  }
}
